var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-responsive',[_c('v-container',[_c('v-row',{staticClass:"my-auto"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-h2 font-weight-bold"},[_vm._v(" Resume ")])],1)],1),_c('v-layout',{staticClass:"my-auto",attrs:{"align-center":""}},[_c('v-sheet',{attrs:{"width":"100vw"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"7","lg":"4"}},[_c('v-card',{attrs:{"flat":""}},[_c('p',{staticClass:"text-h4 font-weight-regular"},[_vm._v(" Experience ")]),_vm._l((_vm.resume.experience),function(position){return _c('v-hover',{key:position.index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"my-2",attrs:{"dark":hover,"flat":"","color":hover ? 'blue-grey':'blue-grey lighten-5'}},[_c('v-card-text',{staticClass:"text-body-2 text-uppercase font-weight-bold"},[_vm._v(_vm._s(position.time))]),_c('v-card-title',{staticClass:"mt-n3 pt-0"},[_vm._v(" "+_vm._s(position.title)+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(position.organization)+" ")])],1)]}}],null,true)})})],2),_c('v-card',{attrs:{"flat":""}},[_c('p',{staticClass:"text-h4 font-weight-regular"},[_vm._v(" Voluntary Experience ")]),_vm._l((_vm.resume.voluntary_experience),function(exp){return _c('v-hover',{key:exp.index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"my-2",attrs:{"dark":hover,"flat":"","color":hover ? 'blue-grey':'blue-grey lighten-5'}},[_c('v-card-text',{staticClass:"text-body-2 text-uppercase font-weight-bold"},[_vm._v(_vm._s(exp.time))]),_c('v-card-title',{staticClass:"mt-n3 pt-0"},[_vm._v(" "+_vm._s(exp.title)+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(exp.organization)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(exp.description)+" ")])],1)]}}],null,true)})})],2)],1),_c('v-col',{attrs:{"cols":"12","md":"5","lg":"3"}},[_c('v-card',{attrs:{"flat":""}},[_c('p',{staticClass:"text-h4 font-weight-regular"},[_vm._v(" Education ")]),_vm._l((_vm.resume.education),function(edu){return _c('v-hover',{key:edu.index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"my-2",attrs:{"dark":hover,"flat":"","color":hover ? 'blue-grey':'blue-grey lighten-5'}},[_c('v-card-text',{staticClass:"text-body-2 text-uppercase font-weight-bold"},[_vm._v(_vm._s(edu.time))]),_c('v-card-title',{staticClass:"mt-n3 pt-0"},[_vm._v(" "+_vm._s(edu.title)+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(edu.institute)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(edu.description)+" ")])],1)]}}],null,true)})})],2),_c('v-card',{attrs:{"flat":""}},[_c('p',{staticClass:"text-h4 font-weight-regular"},[_vm._v(" Awards and Accomplishments ")]),_vm._l((_vm.resume.awards),function(award){return _c('v-hover',{key:award.index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"my-2",attrs:{"dark":hover,"flat":"","color":hover ? 'blue-grey':'blue-grey lighten-5'}},[_c('v-card-text',{staticClass:"text-body-2 text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(award.time)+" ")]),_c('v-card-subtitle',{staticClass:"mt-n3 pt-0"},[_vm._v(" "+_vm._s(award.title)+" ")])],1)]}}],null,true)})})],2),_c('v-card',{attrs:{"flat":""}},[_c('p',{staticClass:"text-h4 font-weight-regular"},[_vm._v(" Publications ")]),_vm._l((_vm.resume.publications),function(publication){return _c('v-hover',{key:publication.index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"my-2",attrs:{"dark":hover,"flat":"","color":hover ? 'blue-grey':'blue-grey lighten-5'}},[_c('v-card-text',{staticClass:"text-body-2 text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(publication.time)+" ")]),_c('v-card-subtitle',{staticClass:"mt-n3 pt-0"},[_vm._v(" "+_vm._s(publication.title)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(publication.link)+" ")])],1)]}}],null,true)})})],2)],1),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }