<template>
  <v-responsive>
    <v-container>
      <v-row class="my-auto">
        <v-card flat>
          <v-card-title class="text-h2 font-weight-bold">
            Resume
          </v-card-title>
        </v-card>
      </v-row>
      <v-layout class="my-auto" align-center>
        <v-sheet width="100vw">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" md="7" lg="4">
              <v-card flat>
                <p class="text-h4 font-weight-regular">
                  Experience
                </p>
                <v-hover v-for="position in resume.experience" :key="position.index" v-slot="{hover}">
                  <v-card :dark="hover" class="my-2" flat :color="hover ? 'blue-grey':'blue-grey lighten-5'">
                    <v-card-text class="text-body-2 text-uppercase font-weight-bold">{{ position.time }}</v-card-text>
                    <v-card-title class="mt-n3 pt-0">
                      {{ position.title }}
                    </v-card-title>
                    <v-card-subtitle>
                      {{ position.organization }}
                    </v-card-subtitle>
                  </v-card>
                </v-hover>
              </v-card>
              <v-card flat>
                <p class="text-h4 font-weight-regular">
                  Voluntary Experience
                </p>
                <v-hover v-for="exp in resume.voluntary_experience" :key="exp.index" v-slot="{hover}">
                  <v-card :dark="hover" class="my-2" flat :color="hover ? 'blue-grey':'blue-grey lighten-5'">
                    <v-card-text class="text-body-2 text-uppercase font-weight-bold">{{ exp.time }}</v-card-text>
                    <v-card-title class="mt-n3 pt-0">
                      {{ exp.title }}
                    </v-card-title>
                    <v-card-subtitle>
                      {{ exp.organization }}
                    </v-card-subtitle>
                    <v-card-text>
                      {{ exp.description }}
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-card>
            </v-col>
            <v-col cols="12" md="5" lg="3">
              <v-card flat>
                <p class="text-h4 font-weight-regular">
                  Education
                </p>
                <v-hover v-for="edu in resume.education" :key="edu.index" v-slot="{hover}">
                  <v-card :dark="hover" class="my-2" flat :color="hover ? 'blue-grey':'blue-grey lighten-5'">
                    <v-card-text class="text-body-2 text-uppercase font-weight-bold">{{ edu.time }}</v-card-text>
                    <v-card-title class="mt-n3 pt-0">
                      {{ edu.title }}
                    </v-card-title>
                    <v-card-subtitle>
                      {{ edu.institute }}
                    </v-card-subtitle>
                    <v-card-text>
                      {{ edu.description }}
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-card>
              <v-card flat>
                <p class="text-h4 font-weight-regular">
                  Awards and Accomplishments
                </p>
                <v-hover v-for="award in resume.awards" :key="award.index" v-slot="{hover}">
                  <v-card :dark="hover" class="my-2" flat :color="hover ? 'blue-grey':'blue-grey lighten-5'">
                    <v-card-text class="text-body-2 text-uppercase font-weight-bold">
                      {{ award.time }}
                    </v-card-text>
                    <v-card-subtitle class="mt-n3 pt-0">
                      {{ award.title }}
                    </v-card-subtitle>
                  </v-card>
                </v-hover>
              </v-card>
              <v-card flat>
                <p class="text-h4 font-weight-regular">
                  Publications
                </p>
                <v-hover v-for="publication in resume.publications" :key="publication.index" v-slot="{hover}">
                  <v-card :dark="hover" class="my-2" flat :color="hover ? 'blue-grey':'blue-grey lighten-5'">
                    <v-card-text class="text-body-2 text-uppercase font-weight-bold">
                      {{ publication.time }}
                    </v-card-text>
                    <v-card-subtitle class="mt-n3 pt-0">
                      {{ publication.title }}
                    </v-card-subtitle>
                    <v-card-text>
                      {{ publication.link }}
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-card>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-sheet>
      </v-layout>
    </v-container>
  </v-responsive>
</template>

<script>
export default {
  name: "Resume",
  props: ['resume'],
  data: () => ({}),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    width() {
      return this.isMobile ? '100vw' : '60vw';
    }
  }
}
</script>

<style scoped>

</style>