<template>
  <v-responsive height="100vh">
    <v-container fill-height>
      <v-layout class="mt-n12 mt-sm-n12 mt-md-n12 mt-lg-n16" align-center>
        <v-sheet :width="width" flat class="mt-n12 mt-sm-n12 mt-md-n12 mt-lg-n16 mx-auto mx-lg-auto">
          <v-row no-gutters>
            <v-col
                v-if="!isMobile"
                sm="6"
                md="6"
                lg="4"
            >
              <v-card
                  class="pa-2"
                  tile
                  flat
              >
                <v-img :src="about.image_src" :lazy-src="about.lazy_image_src" contain>
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-card>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
                lg="8"
            >
              <v-card
                  class="pa-2"
                  tile
                  flat
              >
                <v-card-title class="text-h2 font-weight-bold">{{ about.title }}</v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle class="text-md-body-1 text--darken-3">
                  {{ about.summary }}
                </v-card-subtitle>
                <v-row no-gutters v-for="info in about.bio" :key="info.index">
                  <v-col cols="5" lg="3">
                    <v-card-text class="py-0 my-0 text-lg-h6 font-weight-bold">{{ info.parameter }}</v-card-text>
                  </v-col>
                  <v-col cols="7" lg="9">
                    <v-card-text class="py-0 my-0 text-lg-h6 font-weight-regular">{{ info.value }}</v-card-text>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn
                      large
                      dark
                      rounded
                      target="_blank"
                      color="blue-grey"
                      href="https://firebasestorage.googleapis.com/v0/b/spa-portfolio-78a59.appspot.com/o/Resume_Didula_Egodage.pdf?alt=media&token=77d862c7-4e6e-480f-bfd4-aadf97e0f946"
                  >
                    Download Resume
                  </v-btn>
                <!--Shall put the link and the token as firebase db value-->
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-layout>
    </v-container>
  </v-responsive>
</template>

<script>
export default {
  name: "About",
  props: ['about'],
  data: () => ({}),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    width() {
      return this.isMobile ? '100vw' : '60vw';
    }
  }
}
</script>

<style scoped>

</style>