<template>
  <v-app>
    <v-app-bar
        app
        color="white"
        elevate-on-scroll
    >
      <v-app-bar-nav-icon
          v-if="isMobile"
          @click="isDrawerOpen = !isDrawerOpen">
      </v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
            alt="DLogo"
            class="shrink mr-2"
            contain
            src="https://firebasestorage.googleapis.com/v0/b/spa-portfolio-78a59.appspot.com/o/d.svg?alt=media&token=c9709bfa-89bb-4702-bc21-22837ef723ed"
            transition="scale-transition"
            width="40"
        />
      </div>

      <v-spacer></v-spacer>

      <div v-if="!isMobile">
        <v-btn
            @click="$vuetify.goTo(homeRef,opts)"
            text>
          <span class="mr-2">Home</span>
        </v-btn>
        <v-btn
            @click="$vuetify.goTo(aboutRef,opts)"
            text>
          <span class="mr-2">About</span>
        </v-btn>
        <v-btn
            @click="$vuetify.goTo(resumeRef,opts)"
            text>
          <span class="mr-2">Resume</span>
        </v-btn>
<!--        <v-btn
            @click="$vuetify.goTo(workRef,opts)"
            text>
          <span class="mr-2">Work</span>
        </v-btn>-->
<!--        <v-btn
            target="_blank"
            text>
          <span class="mr-2">Blog</span>
        </v-btn>-->
        <v-btn
            @click="$vuetify.goTo(contactRef,opts)"
            text>
          <span class="mr-2">Contact</span>
        </v-btn>
      </div>

    </v-app-bar>
    <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        transition="scale-transition"
        color="blue-grey"
        @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <v-navigation-drawer
        v-model="isDrawerOpen"
        app
        temporary
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            v-model="group"
        >
          <v-list-item>
            <v-list-item-title @click="$vuetify.goTo(homeRef,opts)">Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="$vuetify.goTo(aboutRef,opts)">About</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="$vuetify.goTo(resumeRef,opts)">Resume</v-list-item-title>
          </v-list-item>

<!--          <v-list-item>
            <v-list-item-title @click="$vuetify.goTo(workRef,opts)">Work</v-list-item-title>
          </v-list-item>-->

<!--          <v-list-item>
            <v-list-item-title>Blog</v-list-item-title>
          </v-list-item>-->

          <v-list-item>
            <v-list-item-title @click="$vuetify.goTo(contactRef,opts)">Contact</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <home v-if="profile && profile.welcome" :welcome="profile.welcome" ref="home"/>
      <about v-if="profile && profile.about" :about="profile.about" ref="about"/>
      <resume v-if="profile && profile.resume" :resume="profile.resume" ref="resume"/>
<!--      <work ref="work"/>-->
<!--      <blog ref="blog"/>-->
      <contact v-if="profile && profile.contact" :contacts="profile.contact.contacts" ref="contact"/>
    </v-main>
    <v-footer v-if="profile && profile.contact">
      <v-row>
        <v-spacer></v-spacer>
        <v-card flat color="transparent">
          <p class="ma-4 text-center text-h5 grey--text font-weight-bold">Follow Me</p>
          <v-row class="ma-4" justify="space-around">
            <v-btn
                :href="follow.link"
                icon
                color="blue-grey"
                v-for="follow in profile.contact.follows" :key="follow.index"
            >
              <v-icon large>{{follow.icon}}</v-icon>
            </v-btn>
          </v-row>
          <p class="text-center grey--text darken-5">{{profile.contact.copyright}}</p>
        </v-card>
        <v-spacer></v-spacer>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import axios from 'axios';

import Home from './components/Home'
import About from "@/components/About";
import Resume from "@/components/Resume";
// import Work from "@/components/Work";
// import Blog from "@/components/Blog";
import Contact from "@/components/Contact";

export default {
  name: 'App',

  components: {
    Home,
    About,
    Resume,
    // Work,
    // Blog,
    Contact
  },

  data: () => ({
    isDrawerOpen: false,
    group: null,
    fab: false,
    opts: {
      duration: 500,
      offset: 0,
      easing: 'easeInOutQuad'
    },
    profile: null
  }),
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  },
  watch: {
    group() {
      this.isDrawerOpen = false
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    homeRef() {
      return this.$refs.home;
    },
    aboutRef() {
      return this.$refs.about;
    },
    resumeRef() {
      return this.$refs.resume;
    },
/*    workRef() {
      return this.$refs.contact;
    },*/
    contactRef() {
      return this.$refs.contact;
    }
  },
  created() {
    axios.get('https://spa-portfolio-78a59-default-rtdb.firebaseio.com/profile.json')
    .then(response => {
      this.profile = response.data;
    })
    .catch(e => {
      console.log(e)
    })
  }
};
</script>
