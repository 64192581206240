<template>
  <v-responsive height="100vh">
    <v-container fill-height>
      <v-layout class="mt-n12 mt-sm-n12 mt-md-n12 mt-lg-n16" align-center>
        <v-row class="mt-n12 mt-sm-n12 mt-md-n12 mt-lg-n16">
          <v-card
              elevation="0"
              class="mx-auto mx-lg-auto"
          >
            <v-list-item>
              <v-list-item-content>
                <v-flex>
                  <v-card-subtitle class="text-h3">{{ welcome.greeting }}</v-card-subtitle>
                  <v-card-title v-if="!isMobile" class="text-h1 font-weight-bold">{{welcome.first_name}} {{welcome.last_name}}</v-card-title>
                  <v-card-title v-if="isMobile" class="text-h2 font-weight-bold">{{welcome.first_name}}</v-card-title>
                  <v-card-title v-if="isMobile" class="text-h2 font-weight-bold">{{welcome.last_name}}</v-card-title>
                  <v-spacer></v-spacer>
                  <v-card-subtitle class="text-h4 text--black">{{ welcome.subtitle }} <br v-if="isMobile"/>
                    <vue-typer :text="welcome.typer"></vue-typer>
                  </v-card-subtitle>
                </v-flex>
              </v-list-item-content>
              <v-list-item-avatar
                  v-if="!isMobile"
                  size="20vw"
                  right
              >
                <v-img :src="welcome.image_src" :lazy-src="welcome.lazy_image_src" alt="portrait">
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </v-row>
      </v-layout>
    </v-container>
  </v-responsive>
</template>

<script>
import {VueTyper} from 'vue-typer';
export default {
  name: "Home",
  props:['welcome'],
  data: () => ({}),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  components: {
    VueTyper
  }
}
</script>

<style scoped>
</style>