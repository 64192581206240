<template>
  <v-responsive>
    <v-container>
      <v-row class="my-auto">
        <v-card flat>
          <v-card-title class="text-h2 font-weight-bold">
            Contact Me
          </v-card-title>
        </v-card>
      </v-row>
      <v-layout class="my-auto" align-center>
        <v-sheet width="100vw">
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3" lg="2">
              <v-card v-for="contact in contacts" :key="contact.index" flat class="my-2 pa-4">
                <v-row>
                  <v-col cols="3">
                    <v-icon
                        large
                        left
                    >
                      {{ contact.icon }}
                    </v-icon>
                  </v-col>
                  <v-col>
                    <div class="font-weight-bold">{{ contact.type }}</div>
                    <div>{{ contact.value }} <v-btn :href="contact.link" target="_blank" v-if="contact.link != null" icon><v-icon>mdi-open-in-new</v-icon></v-btn></div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="5" lg="4">
              <form>
                <v-text-field
                    outlined
                    v-model="name"
                    :error-messages="nameErrors"
                    label="Name"
                    required
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                ></v-text-field>
                <v-text-field
                    outlined
                    v-model="email"
                    :error-messages="emailErrors"
                    label="E-mail"
                    required
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                ></v-text-field>
                <v-text-field
                    outlined
                    v-model="subject"
                    :error-messages="subjectErrors"
                    label="Discussion topic"
                    required
                    @input="$v.subject.$touch()"
                    @blur="$v.subject.$touch()"
                ></v-text-field>
                <v-textarea
                    outlined
                    v-model="message"
                    :error-messages="messageErrors"
                    label="Message"
                    required
                    @input="$v.message.$touch()"
                    @blur="$v.message.$touch()"
                ></v-textarea>
                <v-btn
                    dark
                    large
                    rounded
                    color="blue-grey"
                    class="mr-4 mb-4"
                    @click="submit"
                >
                  Send Message
                </v-btn>
              </form>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-sheet>
      </v-layout>
    </v-container>
  </v-responsive>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  name: "Contact",
  props:['contacts'],
  validations: {
    name: {required},
    email: {required, email},
    subject: {required},
    message: {required}
  },
  data: () => ({
    name: '',
    email: '',
    subject: '',
    message: ''
  }),
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Name is required.')
      return errors
    },
    subjectErrors() {
      const errors = []
      if (!this.$v.subject.$dirty) return errors
      !this.$v.subject.required && errors.push('Subject is required.')
      return errors
    },
    messageErrors() {
      const errors = []
      if (!this.$v.message.$dirty) return errors
      !this.$v.message.required && errors.push('Message is required.')
      return errors
    }
  },
  methods: {
    submit() {
      this.$v.$touch()
    }
  }
}
</script>

<style scoped>

</style>